import React from 'react'
import { Heading } from 'theme-ui'
import { buildResponsiveVariant as rv } from '../utils'

const CardBodyTitle = ({ variant, title, slug, link }) => {
  const linkProps = {
    as: 'a',
    href: link || slug,
    rel: 'noopener noreferrer'
  }

  return (
    <Heading {...linkProps} sx={{ variant: rv(variant, 'title') }}>
      {title}
    </Heading>
  )
}

export default CardBodyTitle
